import * as React from "react"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Title, Subtitle } from "./StyledElements";
import Button from "../../UIComponents/Button";

const NotFoundBlock = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('NotFound')}</Title>
      <Subtitle>{t('NotFound')}</Subtitle>
      <Button url="/" top={30} label={t('BackHome')}/>
    </Container>
  );

};

export default NotFoundBlock;